// index.js - WEB
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import registerServiceWorker from '../../components/src/registerServiceWorker';

ReactDOM.render(
  <Router>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </Router>,
  document.getElementById('root')
);
registerServiceWorker();
