//@ts-nocheck
import React, { lazy, Suspense } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter
} from 'react-router-dom';
/** Routes List start */

import NavBar from '../../blocks/dashboard/src/NavBar.web';

const LandingHomeWeb = lazy(() =>
  import('../../blocks/dashboard/src/LandingHomeWeb.web')
);
const SignUpTermAndCondition = lazy(() =>
  import('../../blocks/TermsAndConditions/src/SignUpTermAndCondition.web')
);
const Login = lazy(() =>
  import('../../blocks/email-account-login/src/Login.web')
);
const Registration = lazy(() =>
  import('../../blocks/email-account-registration/src/Registration.web')
);
const AttentionMessage = lazy(() =>
  import('../../blocks/email-account-registration/src/AttentionMessage.web')
);
const OtpConfirmation = lazy(() =>
  import('../../blocks/otp-input-confirmation/src/OtpConfirmation.web')
);
const SelectPreferLanguage = lazy(() =>
  import('../../blocks/LanguageOptions/src/SelectPreferLanguage.web')
);
const UserProfile = lazy(() =>
  import('../../blocks/user-profile-basic/src/UserProfile.web')
);
const SubjectAndTopicSelction = lazy(() =>
  import('../../blocks/dashboard/src/SubjectAndTopicSelction/SubjectAndTopicSelction.web')
);
const HomeSection = lazy(() =>
  import('../../blocks/dashboard/src/HomeSectionScreens/Home.web')
);
const WatchVideos = lazy(() =>
  import('../../blocks/dashboard/src/HomeSectionScreens/WatchVideosPlalylistSelection.web')
);
const EditWatchVideosPlalylistSelection = lazy(() =>
  import('../../blocks/dashboard/src/HomeSectionScreens/EditWatchVideosPlalylistSelection.web')
);
const TakeTest = lazy(() =>
  import('../../blocks/dashboard/src/HomeSectionScreens/TakeTestSelection.web')
);
const PlayListVideoItem = lazy(() =>
  import('../../blocks/videos/src/PlayListVideoItem.web')
);
const VideoSection = lazy(() =>
  import('../../blocks/videos/src/PlayVideoSection.web')
);
const UserProfileSetting = lazy(() =>
  import('../../blocks/user-profile-basic/src/SettingUserProfile.web')
);
const SettingUserProfileFeedback = lazy(() =>
  import('../../blocks/user-profile-basic/src/SettingUserProfileFeedback.web')
);
const SettingUserProfileTermAndCondition = lazy(() =>
  import('../../blocks/user-profile-basic/src/SettingUserProfileTermAndCondition.web')
);
const SettingUserProfileAboutUs = lazy(() =>
  import('../../blocks/user-profile-basic/src/SettingUserProfileAboutUs.web')
);
const SettingUserProfilePrivacyAndPolicy = lazy(() =>
  import('../../blocks/user-profile-basic/src/SettingUserProfilePrivacyAndPolicy.web')
);
const HomeAboutUs = lazy(() =>
  import('../../blocks/AboutUS/src/FooterLinks/HomeAboutUs.web')
);
const HomePrivacyPolicy = lazy(() =>
  import('../../blocks/AboutUS/src/FooterLinks/HomePrivacyPolicy.web')
);
const HomeTermsAndConditions = lazy(() =>
  import('../../blocks/TermsAndConditions/src/HomeTermsAndConditions.web')
);
const HomeDisclaimer = lazy(() =>
  import('../../blocks/AboutUS/src/FooterLinks/HomeDisclaimer.web')
);
const HomeContactUs = lazy(() =>
  import('../../blocks/contactus/src/ContactUsScreen/ContactUs.web')
);
const Assesment = lazy(() =>
  import('../../blocks/dashboard/src/AssesmentSection/Assesment.web.tsx')
);
const AssesmentStartTest = lazy(() =>
  import('../../blocks/dashboard/src/AssesmentSection/AssesmentStartTest.web.tsx')
);
const AssesmentResult = lazy(() =>
  import('../../blocks/dashboard/src/AssesmentSection/AssesmentResult.web.tsx')
);
const AssesmentScoreCard = lazy(() =>
  import('../../blocks/dashboard/src/AssesmentSection/AssesmentScoreCard.web.tsx')
);
const LeaderBoard = lazy(() =>
  import('../../blocks/Leaderboard/src/LeaderBoardScreen/LeaderBoard.web.tsx')
);
const QuickSurvey = lazy(() =>
  import('../../blocks/Surveys/src/SurveyScreen/QuickSurvey.web')
);
const Dashboard = lazy(() =>
  import('../../blocks/dashboard/src/Dashboard/Dashboard.web')
);
const DashboardVideoPlaylist = lazy(() =>
  import('../../blocks/dashboard/src/Dashboard/DashboardVideoPlaylist.web')
);
const DashboardVideoPlaylistItems = lazy(() =>
  import('../../blocks/dashboard/src/Dashboard/DashboardVideoPlaylistItems.web')
);
const NotificationsLearn = lazy(() =>
  import('../../blocks/notifications/src/NotificationsLearn.web')
);
const LeaderBoardHomeScreen = lazy(() => import("../../blocks/Leaderboard/src/LeaderBoardScreen/LeaderBoardHomeScreen.web"))
/** Routes List End */

/** Private Routes start */
import PrivateRoute from './PrivateRoute.web';
import Loader from '../../components/src/Loader.web';
/** Private Roues End */

function WebRoutes(rootProps: any) {
  // render() {
  return (
    <Router>
      <Suspense fallback={<Loader loading />}>
        <Switch>
          <Route
            path="/"
            exact
            render={props => <LandingHomeWeb {...props} {...rootProps} />}
          />

          <Route path="/login" exact render={props => <Login {...rootProps} {...props} />} />
          <Route
            path="/Sign-in"
            exact
            render={props => <Registration {...rootProps} {...props} />}
          />
          <Route
            path="/Sign-up"
            exact
            render={props => <Registration {...rootProps} {...props} />}
          />
          <Route
            path="/attention"
            exact
            render={props => <AttentionMessage {...rootProps} {...props} />}
          />
          <Route
            path="/Otp"
            exact
            render={props => <OtpConfirmation {...rootProps} {...props} />}
          />
          <Route
            path="/selectLanguage"
            exact
            render={props => <SelectPreferLanguage {...rootProps} {...props} />}
          />
          <Route
            path="/SignUpTermAndCondition"
            exact
            render={props => <SignUpTermAndCondition {...rootProps} {...props} />}
          />
          <Route
            path="/AboutUs"
            exact
            render={props => <HomeAboutUs {...rootProps} {...props} />}
          />
          <Route
            path="/PrivacyPolicy"
            exact
            render={props => <HomePrivacyPolicy {...rootProps} {...props} />}
          />
          <Route
            path="/TermsandConditions"
            exact
            render={props => <HomeTermsAndConditions {...rootProps} {...props} />}
          />
          <Route
            path="/Disclaimer"
            exact
            render={props => <HomeDisclaimer {...rootProps} {...props} />}
          />
          <Route
            path="/ContactUs"
            exact
            render={props => <HomeContactUs {...rootProps} {...props} />}
          />

          {/* Private Route pages*/}

          <PrivateRoute
            path="/UserProfile"
            exact
            render={props => <UserProfile {...rootProps} {...props} />}
          />

          <PrivateRoute
            path="/SubTopicSelection"
            exact
            render={props => <SubjectAndTopicSelction {...rootProps} {...props} />}
          />
          <PrivateRoute
            path="/Home"
            exact
            render={props => <HomeSection {...rootProps} {...props} />}
          />
          <PrivateRoute
            path="/WatchVideos"
            exact
            render={props => <WatchVideos {...rootProps} {...props} />}
          />
          <PrivateRoute
            path="/selectedSubTopics"
            exact
            render={props => <EditWatchVideosPlalylistSelection {...rootProps} {...props} />}
          />
          <PrivateRoute
            path="/selectedTestSubTopics"
            exact
            render={props => <EditWatchVideosPlalylistSelection {...rootProps} {...props} />}
          />
          <PrivateRoute
            path="/TakeTest"
            exact
            render={props => <TakeTest {...rootProps} {...props} />}
          />
          <PrivateRoute
            path="/Playlist"
            exact
            render={props => <PlayListVideoItem {...rootProps} {...props} />}
          />
          <PrivateRoute
            path="/TestPlaylist"
            exact
            render={props => <PlayListVideoItem {...rootProps} {...props} />}
          />
          <PrivateRoute
            path="/VideoPlay"
            exact
            render={props => <VideoSection {...rootProps} {...props} />}
          />
          <PrivateRoute
            path="/TestPlay"
            exact
            render={props => <VideoSection {...rootProps} {...props} />}
          /> 
          <PrivateRoute
            path="/UserProfileSetting"
            exact
            render={props => <UserProfileSetting {...rootProps} {...props} />}
          />
          <PrivateRoute
            path="/ProfileFeedback"
            exact
            render={props => <SettingUserProfileFeedback {...rootProps} {...props} />}
          />
          <PrivateRoute
            path="/ProfileTermAndCondition"
            exact
            render={props => <SettingUserProfileTermAndCondition {...rootProps} {...props} />}
          />
          <PrivateRoute
            path="/ProfileAboutUs"
            exact
            render={props => <SettingUserProfileAboutUs {...rootProps} {...props} />}
          />
          <PrivateRoute
            path="/ProfilePrivacyAndPolicy"
            exact
            render={props => <SettingUserProfilePrivacyAndPolicy {...rootProps} {...props} />}
          />

          <PrivateRoute
            path="/Assesment"
            exact
            render={props => <Assesment {...rootProps} {...props} />}
          />
          <PrivateRoute
            path="/AssesmentStartTest"
            exact
            render={props => <AssesmentStartTest {...rootProps} {...props} />}
          />
           <PrivateRoute
            path="/QuickSurveyCrctAnswers"
            exact
            render={props => <AssesmentStartTest {...rootProps} {...props} />}
          />
          <PrivateRoute
            path="/AssesmentResult"
            exact
            render={props => <AssesmentResult {...rootProps} {...props} />}
          />
          <PrivateRoute
            path="/AssesmentScoreCard"
            exact
            render={props => <AssesmentScoreCard {...rootProps} {...props} />}
          />
          <PrivateRoute
            path="/LeaderBoardScoreCard"
            exact
            render={props => <LeaderBoard {...rootProps} {...props} />}
          />
          <PrivateRoute
            path="/LeaderBoardSubjectScoreCard"
            exact
            render={props => <LeaderBoard {...rootProps} {...props} />}
          />
          <PrivateRoute
            path="/LeaderBoard"
            exact
            render={props => <LeaderBoardHomeScreen {...rootProps} {...props} />}
          />
          <PrivateRoute
            path="/QuickSurvey"
            exact
            render={props => <QuickSurvey {...rootProps} {...props} />}
          />
           <PrivateRoute
            path="/Dashboard"
            exact
            render={props => <Dashboard {...rootProps} {...props} />}
          />
          <PrivateRoute
            path="/DashboardVideoPlaylist"
            exact
            render={props => <DashboardVideoPlaylist {...rootProps} {...props} />}
          />
           <PrivateRoute
            path="/DashboardTestPlaylist"
            exact
            render={props => <DashboardVideoPlaylist {...rootProps} {...props} />}
          />
          <PrivateRoute
            path="/DashboardVideoPlaylistItems"
            exact
            render={props => <DashboardVideoPlaylistItems {...rootProps} {...props} />}
          />
           <PrivateRoute
            path="/DashboardTestPlaylistItems"
            exact
            render={props => <DashboardVideoPlaylistItems {...rootProps} {...props} />}
          />
           <PrivateRoute
            path="/LeaderBoardTestPlaylistItems"
            exact
            render={props => <DashboardVideoPlaylistItems {...rootProps} {...props} />}
          />
           <PrivateRoute
            path="/Notifications"
            exact
            render={props => <NotificationsLearn {...rootProps} {...props} />}
          />
        </Switch>
      </Suspense>
    </Router>
  );
  // }
}

//@ts-ignore
export default withRouter(WebRoutes);
