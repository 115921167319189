import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { notification } from 'antd'

const firebaseConfig = {
  apiKey: "AIzaSyD8jVMVWI84VTRT41GgVLYALHdXZddf9rU",
  authDomain: "pathanti.firebaseapp.com",
  projectId: "pathanti",
  storageBucket: "pathanti.appspot.com",
  messagingSenderId: "882166439342",
  appId: "1:882166439342:web:e5f09383e56311cde57c3f"
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const getTokenData = () => {
  return getToken(messaging, { vapidKey: 'BM2QQX11sp9CnAjkD4lbxdpSIguMj2cDQFgTwRcR7HMNpIHEbK3suLALD5V-YHXSAQWpP5ixQoxk_tvcDJaAxj8' }).then((currentToken) => {
    if (currentToken) {
      console.log('@@@ Firebase Registration TOKEN =====', currentToken);
      localStorage.setItem('fcmToken', currentToken.toString())
    } else {
      console.log('@@@ Firebase No registration token available. Request permission to generate one.');
    }
  }).catch((err) => {
    console.log('@@@ Firebase Error: An error occurred while retrieving token. ', err);
    // catch error while creating client token
  });
}

onMessage(messaging, (payload) => {
  console.log('@@@ Notification Payload =======', payload);
  notification.open({
    message: payload.notification.title,
    description:
      payload.notification.body,
    onClick: () => {
      console.log('Notification Clicked!');
    },
  });
});