//** App.tsx - WEB ***//
import React, { Component } from 'react';
// import firebase from 'firebase';
import WebRoutes from './WebRoutes';
import HomeScreen from '../../components/src/HomeScreen';
import './App.css';
import 'antd/dist/antd.css';
import { withTranslation } from "react-i18next";
import "video.js/dist/video-js.css";
import "videojs-extra-buttons/dist/videojs-extra-buttons.css";
import "videojs-playlist-ui/dist/videojs-playlist-ui.vertical.css";
import { getTokenData } from './firebaseInit.js';

if (!HomeScreen.instance) {
  const defaultProps = {
    navigation: null,
    id: 'HomeScreen'
  };
  const homeScreen = new HomeScreen(defaultProps);
}


class App extends Component {
  async componentDidMount() {
    Notification.requestPermission().then((permission) => {
      console.log('@@@ Notification Permission ======', permission);
      getTokenData();
    });
  }

  render() {
    return (
      <>
        <WebRoutes {...this.props}/>
      </>
    );
  }
}

const enhance = (
  withTranslation()
);

export default enhance(App);
