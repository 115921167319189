import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import English from "../../blocks/utilities/src/Languages/English.json"
import Hindi from "../../blocks/utilities/src/Languages/Hindi.json"
import Gujarati from "../../blocks/utilities/src/Languages/Gujarati.json"

i18n.use(LanguageDetector).init({
  // we init with resources
  resources: {
    en: {
      translations: English
    },
    hin: {
      translations: Hindi
    },
    guj: {
      translations: Gujarati
    }
  },
  fallbackLng: "en",
  debug: true,

  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ","
  },

  react: {
    wait: true
  }
});

export default i18n;